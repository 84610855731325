import React from 'react';
import { useTranslation } from 'react-i18next';
import './RelatedStories.css';

const StoryCard = ({ image, title, date, link }) => {
    const { t } = useTranslation();

    return (
        <div className='story-card'>
            <img src={image} alt={title} className='story-image' />
            <div className='story-content'>
                <h3 className='story-title'>{t(title)}</h3>
                <p className='story-date'>{t(date)}</p>
            </div>
        </div>
    );
};

const RelatedStories = ({ stories }) => {
    const { t } = useTranslation();
    return (
        <section className='section_featured-feed'>
            <div className='px9'>
                <div className='padding-section-medium'>
                    <div className='related-stories'>
                        <div className='vertical-heading-container'>
                            <p className='text-style-vertical'>{t('story.header')}</p>
                        </div>
                        <div className='story-list'>
                            {stories.map((story, index) => (
                                <StoryCard
                                    key={index}
                                    image={story.image}
                                    title={story.title}
                                    date={story.date}
                                    link={story.link}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RelatedStories;
