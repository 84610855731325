import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomeSection.css';
import Images from '../../images';
import { useTranslation } from 'react-i18next';

const WelcomeSection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='welcome-section'>
            <div className='left-section '>
                <img src={Images.haTM} alt='Welcome' className='ha-trademark' />{' '}
                <div className='button-container'>
                    <button className='visit-button' onClick={() => navigate('/visit')}>
                        {t('welcome.visit')}
                    </button>
                </div>
            </div>
            <div className='right-section text-left'>
                <h2 className='text-upper'>
                    {t('welcome.welcome')} <span className='text-bold highlight'>{t('welcome.farm')}</span>
                </h2>
                <h2 className='text-upper'>
                    {t('welcome.welcome2')} <span className='text-bold'>{t('welcome.future')}</span>
                </h2>
                <p>
                    <span className='text-bold'>{t('welcome.HA')}</span> {t('welcome.findSolution')}
                    <span className='text-bold'>{t('welcome.workingFarm')}</span>
                    {t('welcome.2026')} <span className='text-bold'>{t('welcome.sustainability')}</span>
                </p>
            </div>
        </div>
    );
};

export default WelcomeSection;
