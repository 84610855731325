// src/BookingExperiencesSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './BookingExperiencesSection.css';
import Images from '../../images';
import { Link } from 'react-router-dom';

const BookingExperiencesSection = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='booking-experiences-section' key='booking-1'>
                <div className='px9'>
                    <div className='container-large'>
                        <div className='padding-section-large'>
                            <div className='grid-layout'>
                                <div className='experience-images'>
                                    <img
                                        src={Images.farmOverviewVisitors}
                                        alt='Coffee farm'
                                        className='collage-left_image-1'
                                    />
                                    <img src={Images.floral} alt='Coffee farm2' className='collage-left_image-2' />
                                    <img src={Images.roasting} alt='Coffee farm3' className='collage-left_image-3' />
                                    <img src={Images.gate} alt='Coffee farm4' className='collage-left_image-4' />
                                </div>
                                <div className='text-upper'>
                                    <h2 className='text-md'>
                                        {t('bookTour.header1')}
                                        <span className='text-bold'>{t('bookTour.header2')}</span>
                                    </h2>
                                    <p>{t('bookTour.text')}</p>
                                    <div className='horizontal-divider'>
                                        <div className='line-holder'>
                                            <div className='left-line-animation'></div>
                                        </div>
                                        <a href='/visit' className='action-link text-upper'>
                                            {t('bookTour.button')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='vertical-divider'></div>
            </section>
            <section className='booking-experiences-section' key='booking-2'>
                <div className='px9'>
                    <div className='container-large'>
                        <div className='padding-section-large'>
                            <div className='grid-layout'>
                                <div className='text-upper'>
                                    <h2 className='text-md text-upper'>
                                        {t('bookExperience.header1')}
                                        <span className='text-bold'>{t('bookExperience.header2')}</span>
                                    </h2>
                                    <p>{t('bookExperience.text')}</p>
                                    <div className='horizontal-divider'>
                                        <a href='/visit' className='action-link text-upper'>
                                            {t('bookExperience.button')}
                                        </a>
                                        <div className='line-holder-right'>
                                            <div className='right-line-animation'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='experience-images right'>
                                    <img
                                        src={Images.coffeeTasting}
                                        alt='Coffee farm'
                                        className='collage-right_image-1'
                                    />
                                    <img
                                        src={Images.pullingCoffee}
                                        alt='Coffee farm2'
                                        className='collage-right_image-2'
                                    />
                                    <img src={Images.floral} alt='Coffee farm3' className='collage-right_image-3' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='vertical-divider'></div>
            </section>
            <section className='booking-experiences-section' key='booking-3'>
                <div className='px9'>
                    <div className='container-large'>
                        <div className='padding-section-large'>
                            <div class='media-center_container'>
                                <img
                                    src={Images.TM_center}
                                    loading='lazy'
                                    alt='An illustration of a coffee bean.'
                                    class='media-center_image'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BookingExperiencesSection;
