import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import Images from '../../images';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className='footer4_component'>
            <div className='p9'>
                <div className=''>
                    <div className='padding-bottom padding-xxlarge'>
                        <div className='footer4_top-wrapper'>
                            <a href='/' aria-current='page' className='footer4_logo-link w-nav-brand w--current'>
                                <img
                                    src={Images.TM_top}
                                    loading='lazy'
                                    alt='Starbucks TM Logo'
                                    className='footer4_logo'
                                />
                            </a>
                            <div className='w-layout-grid footer4_social-list'>
                                <a
                                    href='https://www.starbucks.com/'
                                    target='_blank'
                                    className='text-link is-small text-color-white hover-brown w-inline-block'
                                >
                                    <div className='text-upper text-bold'>{t('footer.contact')}</div>
                                </a>
                                <a
                                    href='https://www.google.co.uk/maps/place/Hacienda+Alsacia+Starbucks+Coffee+Farm/@10.1001007,-84.1949831,17z/data=!3m1!4b1!4m6!3m5!1s0x8fa0f773b6f51363:0x28030dd4c352714a!8m2!3d10.1001007!4d-84.1949831!16s%2Fg%2F11ghfhj4dt?entry=ttu&g_ep=EgoyMDI0MDgyMS4wIKXMDSoASAFQAw%3D%3D'
                                    target='_blank'
                                    className='text-link is-small text-color-white hover-brown w-inline-block'
                                >
                                    <div className='icon-embed-xsmall display-inline-block w-embed'>
                                        <svg
                                            width='100%'
                                            height='100%'
                                            viewBox='0 0 7 12'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M3.5625 0.5C3.08984 0.5 2.64225 0.589517 2.21973 0.768555C1.80436 0.947592 1.44092 1.19287 1.12939 1.50439C0.817869 1.81592 0.572592 2.17936 0.393555 2.59473C0.214517 3.01725 0.125 3.46484 0.125 3.9375C0.125 4.41016 0.221679 4.92578 0.415039 5.48438C0.615561 6.04297 0.876951 6.63916 1.19922 7.27295C1.52149 7.90674 1.88672 8.57454 2.29492 9.27637C2.71029 9.98536 3.13281 10.7266 3.5625 11.5C3.99219 10.7266 4.41113 9.98536 4.81934 9.27637C5.2347 8.57454 5.60351 7.90674 5.92578 7.27295C6.24805 6.63916 6.50586 6.04297 6.69922 5.48438C6.89974 4.92578 7 4.41016 7 3.9375C7 3.46484 6.91048 3.01725 6.73145 2.59473C6.55241 2.17936 6.30713 1.81592 5.99561 1.50439C5.68408 1.19287 5.32064 0.947592 4.90527 0.768555C4.48275 0.589517 4.03516 0.5 3.5625 0.5ZM3.5625 5.3125C3.18294 5.3125 2.85889 5.17822 2.59033 4.90967C2.32178 4.64111 2.1875 4.31706 2.1875 3.9375C2.1875 3.55794 2.32178 3.23389 2.59033 2.96533C2.85889 2.69678 3.18294 2.5625 3.5625 2.5625C3.94206 2.5625 4.26611 2.69678 4.53467 2.96533C4.80322 3.23389 4.9375 3.55794 4.9375 3.9375C4.9375 4.31706 4.80322 4.64111 4.53467 4.90967C4.26611 5.17822 3.94206 5.3125 3.5625 5.3125Z'
                                                fill='CurrentColor'
                                            ></path>
                                        </svg>
                                    </div>
                                    <div className='text-upper text-bold'>{t('footer.findus')}</div>
                                </a>
                                <a
                                    href='https://www.instagram.com/haciendaalsacia/'
                                    target='_blank'
                                    className='text-link is-small text-color-white hover-brown w-inline-block'
                                >
                                    <div className='text-upper text-bold'>{t('footer.instagram')}</div>
                                </a>
                                <a
                                    href='https://www.linkedin.com/company/hacienda-alsacia/'
                                    target='_blank'
                                    className='text-link is-small text-color-white hover-brown w-inline-block'
                                >
                                    <div className='text-upper text-bold'>{t('footer.linkedin')}</div>
                                </a>
                                <a
                                    href='https://www.tripadvisor.co.uk/Attraction_Review-g3361169-d13801358-Reviews-Hacienda_Alsacia_Starbucks_Coffee_Farm-Sabanilla_Province_of_Alajuela.html'
                                    target='_blank'
                                    className='text-link is-small text-color-white hover-brown w-inline-block'
                                >
                                    <div className='text-upper text-bold'>{t('footer.tripAdvisor')}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='footer4_bottom-wrapper pt6 flex items-center justify-between'>
                        <img
                            src={Images.tripadvisor}
                            loading='lazy'
                            alt='Trip Advisor Logo'
                            className='footer4_tripadvisor_logo'
                        />

                        <div className='footer4_bottom-right flex-grow'>
                            <div className='footer4_bottom-list flex-grow'>
                                <a href='#' className='text-link is-footer underline-trail'>
                                    <div className='text-upper'>{t('footer.cookie')}</div>
                                </a>
                                <a
                                    href='https://www.starbucks.com/terms/privacy-policy/'
                                    target='_blank'
                                    className='text-link is-footer underline-trail'
                                >
                                    <div className='text-upper'>{t('footer.privacy')}</div>
                                </a>
                                <a
                                    href='https://www.starbucks.com/terms/starbucks-terms-of-use/'
                                    target='_blank'
                                    className='text-link is-footer underline-trail'
                                >
                                    <div className='text-upper'>{t('footer.termOfUse')}</div>
                                </a>
                                <a
                                    href='https://www.starbucks.com/'
                                    target='_blank'
                                    className='text-link is-footer underline-trail'
                                >
                                    <div className='text-upper'>{t('footer.starbucks')}</div>
                                </a>
                            </div>
                            <div className='footer4_credit-text'>
                                <span>
                                    <sup>©</sup> {t('footer.credit', { year: new Date().getFullYear() })}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
